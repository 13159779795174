

















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "CellContentPopover",
})
export default class CellContentPopover extends Vue {
  @Prop({ required: true, default: "" })
  value!: string;

  @Prop({ required: false, default: 20 })
  length!: number;

  private _isLongContent = false;

  get isLongContent() {
    return this.value && this.value.length > this.length;
  }

  get shortContent() {
    if (this.isLongContent) {
      return this.value.substr(0, this.length);
    } else {
      return this.value;
    }
  }
}
