

















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "CellContentExpand",
})
export default class CellContentExpand extends Vue {
  @Prop({ required: true, default: "" })
  value!: string;

  @Prop({ required: false, default: 20 })
  length!: number;

  shortContent = "";
  private _isLongContent = false;

  isShortMode = true;

  created() {
    if (!this.value) {
      this.shortContent = "";
    } else {
      if (this.value.length > this.length) {
        this._isLongContent = true;

        this.shortContent = this.value.substr(0, this.length);
      }
    }
  }

  get isLongContent() {
    return this._isLongContent;
  }
}
