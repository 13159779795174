
























































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import {
  DataDictionaryDtoPagedResultDto,
  FundDto,
  FundProjectApplyDto,
  AuditFlowScope,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import CellContentExpand from "@/components/CellContentExpand/index.vue";
import CellContentPopover from "@/components/CellContentPopover/index.vue";

@Component({
  name: "FundProjectList",
  components: {
    CellContentPopover,
    CellContentExpand,
    PagedTableView,
    AbSelect,
    AgileAuditTag,
  },
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class FundProjectList extends Vue {
  tableItems: FundDto[] = [];
  typeList: any = [];

  queryForm: any = {
    name: "",
    projectType: "",
  };

  created() {
    this.fetchEnumType();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundProjectType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "FundType", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.typeList = res.items;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.fundProjectApply.getAll(params);
  }

  // 新建
  handleCreate() {
    this.$router.push({ name: "fundProjectCreate" });
  }

  // 查看详情
  jumpDetail(row: FundProjectApplyDto) {
    this.$router.push({
      name: "fundProjectApplyDetail",
      params: { id: row.id!.toString() },
    });
  }

  // 查看审核记录
  handleAuditFlow(row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.FundProjectApply,
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }

  // 关闭筹款
  handleDisableFundRaising(id: number) {
    this.$confirm("确定要关闭筹款吗?", "提示").then(async () => {
      await api.fund.disableFundRaising({ fundId: id }).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功!",
        });
      });
    });
  }
}
